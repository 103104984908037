/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use pre-registered patient columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

import { Chip, Typography, useTheme } from '@mui/material';

import { GridAlignment, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';

import { convertMedicalReviewStatusToString } from '@ngt/forms-medical-review'

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';



/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../apis/dtos';


/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const usePatientsColumns = (user: any, countries: Dtos.ICountry[], institutions: Dtos.IInstitution[], reviewers: Dtos.IReviewer[]): GridColDef[] => {
    const theme = useTheme();

    return [
        {
            field: 'studyNumber',
            headerName: 'Paticipant Number',
            width: 170
        },
        {
            field: 'treatmentArm',
            headerName: 'Cohort',
            width: 140
        },
        {
            field: 'country',
            headerName: 'Country',
            valueGetter: (params: GridValueGetterParams<any, Dtos.IPatient>) => countries?.find(c => c.id === institutions?.find(i => i.id === params.row.institutionId)?.countryId)?.name,
            width: 200,
            hide: true
        },
        {
            field: 'institutionCode',
            headerName: 'Institution',
            //renderHeader: params => <><FontAwesomeIcon icon={faBuilding} />&nbsp;Code</>,
            valueGetter: (params: GridValueGetterParams<any, Dtos.IPatient>) => institutions?.find(i => i.id === params.row.institutionId)?.code,
            renderCell: (params: GridValueGetterParams<any, Dtos.IPatient>) => {
                if (!params.value) {
                    return <div />;
                }

                return (
                    <Chip
                        label={<Typography sx={{ fontSize: '0.8rem' }}>{params.value}</Typography>}
                        size="small"
                        color="secondary"
                    />
                )
            },
            width: 120
        },
        {
            field: 'institutionName',
            headerName: 'Institution Name',
            valueGetter: (params: GridValueGetterParams<any, any>) => institutions?.find(i => i.id === params.row.institutionId)?.name,
            flex: 1
        },
        {
            field: 'enteredDate',
            headerName: 'Randomisation Date',
            valueGetter: (params: GridValueGetterParams<any, any>) => params.row.enteredDate ? DateTime.fromISO(params.row.enteredDate).toFormat('dd/MM/yyyy') : undefined,
            width: 180
        },
        {
            field: 'medicalReviewers',
            headerName: 'Reviewers',
            valueGetter: (params: GridValueGetterParams<any, any>) => {
                return params.row.medicalReviewers?.reduce((a: string, b: number) => {
                    const reviewer = reviewers?.find(x => x.id === b);

                    if (!reviewer) {
                        return a;
                    }

                    if (a !== '') {
                        return `${a}, ${reviewer.name}`;
                    }

                    return reviewer.name;
                }, '')
            },
            width: 180
        },
        {
            field: 'reviewStatus',
            headerName: 'Status',
            valueGetter: (params: GridValueGetterParams<any, any>) => {
                return convertMedicalReviewStatusToString(params.row.reviewStatus)
            },
            width: 120
        },
        {
            field: 'requiresAttention',
            headerName: 'Requires Attention',
            renderHeader: () => <div></div>,
            valueGetter: (params: GridValueGetterParams<any, any>) => {
                if (!params.row.reviewStatus) {
                    return 1;
                }

                return params.row.requiresAttention?.reduce((a: number, b: number) => {
                    const reviewer = reviewers?.find(x => x.id === b);

                    if (!reviewer) {
                        return a;
                    }

                    if (reviewer.usernames?.map(u => u.toLowerCase())?.includes(user?.userName!.toString().toLowerCase())) {
                        return 1;
                    }

                    return a;
                }, 0)
            },
            renderCell: (params: GridValueGetterParams<any, Dtos.IPatient>) => {
                if (!params.value || params.value === 0) {
                    return <div />;
                }

                return (
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        size="1x"
                        style={{
                            color: theme.palette.warning.main,
                            fontSize: '1.275rem'
                        }}
                    />
                )
            },
            align: 'center' as GridAlignment,
            width: 100
        }
    ]
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default usePatientsColumns;