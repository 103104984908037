import { Box, Theme, Tooltip, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { SurgeryPathology as SurgeryPathologyDto, BreastSurgeryType, NodalSurgeryType } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import FormDates from '../../components/form/FormDates';
import Annotations from '../../components/form/Annotations';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;
const opmsUrl = global.OPMS_URL as string;


interface ISurgeryPathologyProps {
}

interface INodalSurgeryProps {

}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 2)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 4,
    lg: 5,
    xl: 5
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 7,
    lg: 6,
    xl: 6
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const conditionSubscription = { value: true };

const hasNoNodalSurgeryTypes: ICondition<SurgeryPathologyDto> = ({ formState: { value } = {} }) => !value?.nodalSurgeryTypes || value?.nodalSurgeryTypes?.length === 0;
const hasNodalSurgeryTypes: ICondition<SurgeryPathologyDto> = ({ formState: { value } = {} }) => !!value?.nodalSurgeryTypes && value?.nodalSurgeryTypes?.length > 0;

const hasNoBreastSurgeryTypes: ICondition<SurgeryPathologyDto> = ({ formState: { value } = {} }) => !value?.breastSurgeryTypes || value?.breastSurgeryTypes?.length === 0;
const hasBreastSurgeryTypes: ICondition<SurgeryPathologyDto> = ({ formState: { value } = {} }) => !!value?.breastSurgeryTypes && value?.breastSurgeryTypes?.length > 0;

const nodalTypesColumns: IGroupedSubformGridCell<NodalSurgeryType>[] = [
    //{
    //    name: 'event',
    //    content: (
    //        <Input
    //            component={OpenClinicaFormDisplay}
    //            openClinicaUrl={openClinicaUrl}
    //            eventCrfIdName="eventCrfId"
    //            studyEventIdName="studyEventId"
    //            btnMinWidth="140px"
    //            eventCrfIdPrependParentName
    //            eventCrfIdTableCell
    //            studyEventIdPrependParentName
    //            studyEventIdTableCell
    //            textValueButton
    //        />
    //    ),
    //    sortValue: (row) => row?.event
    //},
    {
        name: 'surgeryDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.surgeryDate
    },
    {
        name: 'surgeryType',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.surgeryType
    }
];

const breastSurgeryTypesColumns: IGroupedSubformGridCell<BreastSurgeryType>[] = [
    //{
    //    name: 'event',
    //    content: (
    //        <Input
    //            component={OpenClinicaFormDisplay}
    //            openClinicaUrl={openClinicaUrl}
    //            eventCrfIdName="eventCrfId"
    //            studyEventIdName="studyEventId"
    //            btnMinWidth="140px"
    //            eventCrfIdPrependParentName
    //            eventCrfIdTableCell
    //            studyEventIdPrependParentName
    //            studyEventIdTableCell
    //            textValueButton
    //        />
    //    ),
    //    sortValue: (row) => row?.event
    //},
    {
        name: 'surgeryTypeDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.surgeryTypeDate
    },
    {
        name: 'surgeryType',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.surgeryType
    }
];


const SurgeryPathology: FunctionComponent<ISurgeryPathologyProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>

                <GroupedField
                    name="laterality"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField
                    name="surgeryDate"
                    component={DateDisplay}
                    GroupStyleProps={groupStyleProps}
                />       
                
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Type of Breast surgery for Primary Tumour
                    </Typography>
                    <Condition
                        type={SurgeryPathologyDto}
                        condition={hasBreastSurgeryTypes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.gridSize}>

                            <GroupedSubformGrid
                                type={BreastSurgeryType}
                                name="breastSurgeryTypes"
                                columns={breastSurgeryTypesColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={SurgeryPathologyDto}
                        condition={hasNoBreastSurgeryTypes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Types of Breast surgery for Primary Tumour"
                                description="There is no type of breast surgery for primary tumour associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <Box style={{ marginTop: '4px' }}>
                    <GroupedField
                        name="whatWasTheMaximumMicroscopicSizeOfTheInvasiveCarcinoma"
                        component={NumericDisplay}
                        GroupStyleProps={groupStyleProps}
                        label={
                            <Tooltip title="NOTE: If there was re-excision of margin, use the maximum dimension of the entire tumour as reported by the pathologist.">
                                <Box>What was the maximum microscopic size of the Invasive Carcinoma (mm):</Box>
                            </Tooltip>
                        }
                    />
                </Box>

                <GroupedField name="invasiveHistologicType"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="histologicGrade"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="invasiveInvasiveCarcinomaTypeHistologicType"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="wasCarcinomaInSituPresentInTheIpsilateralBreast"
                    component={NumericDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="histologicTypeOfCarcinomaInSitu"
                    component={NumericDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="wasTheIpsilateralDCISOrMixedCarcinomaInSituMultifocalOrMulticentric"
                    component={NumericDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="wereAllOfTheMarginsClearForInvasiveCarcinomaAndAnyAssociatedDCIS"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                    label={
                        <Tooltip title="That is, invasive cancer cells or DCIS were NOT found to be adjacent to any inked edge/surface of the specimen. Please note: This field may be blank for earlier participants.">
                            <Box>Were all of the margins clear for invasive carcinoma and any associated DCIS?</Box>
                        </Tooltip>
                    }
                />
                <GroupedFields>
                    <GroupedField name="whatWasTheFinalMinimumRadialSurgicalMarginsForInvasiveCarcinomaAndAnyAssociatedDCIS"
                        component={NumericDisplay}
                        GroupStyleProps={groupStyleProps}
                        label={
                            <Tooltip title="Please note: if there was no residual invasive carcinoma or associated DCIS in re-excision specimen, participant was stratified in the >2mm group.">
                                <Box>
                                    What was the final minimum radial surgical margins for invasive carcinoma and any associated DCIS?
                                    <br /><br />
                                    <strong>THIS IS A STRATIFICATION FACTOR: PLEASE REVIEW THIS DATA IN DETAIL.</strong>
                                </Box>
                            </Tooltip>
                        }
                    />
                    <GroupedField name="stratificationSurgicalMargin1"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField name="stratificationSurgicalMargin2"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <GroupedField name="evidenceOfClinicalOrPathogenicT4Disease"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="wasThereAnyLymphovascularInvasion"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Type of nodal surgery
                    </Typography>

                    <Condition
                        type={SurgeryPathologyDto}
                        condition={hasNodalSurgeryTypes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.gridSize}>
                            <GroupedSubformGrid
                                type={NodalSurgeryType}
                                name="nodalSurgeryTypes"
                                columns={nodalTypesColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={SurgeryPathologyDto}
                        condition={hasNoNodalSurgeryTypes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Types of nodal surgery"
                                description="There is no type of nodal surgery associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <Box style={{ marginTop: '4px' }}>
                    <GroupedField name="numberOfNodesRemoved"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                        label={
                            <Tooltip title="NOTE: Total consists of all SNB and Axillary dissection nodes if both procedures performed">
                                <Box>
                                    Number of nodes removed
                                </Box>
                            </Tooltip>
                        }
                    />
                </Box>
                
                <GroupedField name="finalPathologicLymphNodeStatusOfAxilla"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <Typography variant="h2" className={classes.subtitle}>
                    Hormone Receptor status
                </Typography>

                <Typography variant="h5" className={classes.subtitle}>
                    Oestrogen Receptor status
                </Typography>

                <GroupedField name="percentageOfTumourCellsStained"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField name="intensityOfStaining"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField name="resultDeterminedFrom"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <Typography variant="h5" className={classes.subtitle}>
                    Progesterone Receptor status
                </Typography>

                <GroupedField name="percentageOfTumourCellsStained1"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField name="intensityOfStaining1"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField name="resultDeterminedFrom1"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <Typography variant="h2" className={classes.subtitle}>
                    HER2 status
                </Typography>

                <GroupedField name="heR2Immunohistochemistry"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                    label="HER2 Immunohistochemistry (IHC) Score"
                />

                <GroupedField name="heR2InSituHybridisationChemistry"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                    label="HER2 In Situ Hybridisation (ISH) Score"
                />

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />

            </ContextMedicalReviewForm>
        </>
    );
};

export default SurgeryPathology;
