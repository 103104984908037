/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import { IInputRenderProps } from '@ngt/forms-core';
import { Button, Theme, Tooltip } from '@mui/material';
import { FormsContext, IValidationError } from '@ngt/forms';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-duotone-svg-icons';
import { PatientContext } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';


declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const opmsUrl = global.OPMS_URL as string;

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type SymbolFieldProps = IInputRenderProps<string, IValidationError>

export interface IOpmsFileDisplayProps extends SymbolFieldProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        minWidth: '0',
        padding: theme.spacing(1)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const OpmsFileDisplay: React.FunctionComponent<IOpmsFileDisplayProps> = ({
    inputRender: { state: { value, ...restInputState }, actions: { ...restInputActions } },
    ...rest
}) => {
    const { classes } = useStyles();
    const forms = useContext(FormsContext);

    const { data: patient } = useContext(PatientContext);

    if (value === null || value === undefined) {
        return null;
    }

    return (
        <Tooltip title="Download">
            <Button color="primary" variant="contained" className={classes.button} component="a" target="_blank" href={`${opmsUrl}document/patient/${patient?.studyNumber}/${encodeURIComponent(value)}`}>
                <FontAwesomeIcon icon={faDownload} />
            </Button>
        </Tooltip>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default OpmsFileDisplay;
