import React, { FunctionComponent, useContext } from 'react';
import { FormsContext, FormsLayout, useAuthenticatedUser } from '@ngt/forms';
import { Navigate, Route, Routes, useRoutes, RouteObject, Outlet } from 'react-router-dom';
import { Patients, Patient, MedicalReview, MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute, MedicalReviewExtensionContext, IMedicalReviewFormMapping, MedicalReviewsByPatientStudyNumberRoute, MedicalReviewByPatientStudyNumberAndRepeatRoute, MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute, QuerySummary } from '@ngt/forms-medical-review';
import { TrialsExtensionContext, useInstitutions } from '@ngt/forms-trials';
import GeneralInformation from './containers/form/GeneralInformation';
import SurgeryPathology from './containers/form/SurgeryPathology';
import Death from './containers/form/Death';
import IFramePage from './containers/IFramePage';
import ErrorPage, { ErrorTypeEnum } from './containers/error/ErrorPage';
import BaselineMedicalHistory from './containers/form/BaselineMedicalHistory';
import BaselineToxicity from './containers/form/BaselineToxicity';
import NonProtocolTherapy from './containers/form/NonProtocolTherapyForm';
import CancerEventsForm from './containers/form/CancerEvents';
import BreastImaging from './containers/form/BreastImaging';
import TreatmentToxicityForm from './containers/form/TreatmentToxicityForm';
import EndocrineTherapyForm from './containers/form/EndocrineTherapyForm';
import AdjuvantIpsilateralBreastRadiationTherapy from './containers/form/AdjuvantIpsilateralBreastRadiationTherapy';
import ClinicalAssessmentForm from './containers/form/ClinicalAssessmentForm';
import ProsignaAssayResults from './containers/form/ProsignaAssayResults';

//import IFramePage from './containers/IFramePage';

const formMapping: Record<string, IMedicalReviewFormMapping> = {
    "general": {
        components: <GeneralInformation />
    },
    "surgery-pathology": {
        components: <SurgeryPathology />
    },
    "baseline-medical-history": {
        components: <BaselineMedicalHistory />
    },
    "baseline-toxicity-data": {
        components: <BaselineToxicity />
    },
    "death": {
        components: <Death />
    },
    "non-protocol-therapy-form": {
        components: <NonProtocolTherapy />
    },
    "cancer-events": {
        components: <CancerEventsForm />
    },
    "breast-imaging-form": {
        components: <BreastImaging />
    },
    "treatment-toxicities": {
        components: <TreatmentToxicityForm />
    },
    "endocrine-therapy-form": {
        components: <EndocrineTherapyForm />
    },
    "adjuvant-ipsilateral-breast-radiation-therapy": {
        components: <AdjuvantIpsilateralBreastRadiationTherapy />
    },
    "clinical-assessment-form": {
        components: <ClinicalAssessmentForm />
    },
    "prosigna-assay-results": {
        components: <ProsignaAssayResults />
    }
}

const AppRoutes: FunctionComponent = () => {
    const { loading, error } = useInstitutions();

    if (!loading && error) {
        return (
            <ErrorPage errorType={parseInt(error.errorCode) ?? ErrorTypeEnum.e500} />
        )
    }

    return (
        <Routes>
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/:formDefinitionCode/:formRepeat/*`}
                element={
                    <MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute
                        resolveBeforeLoad
                        formMapping={formMapping}
                    >
                        <QuerySummary />
                    </MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute>
                }
            /> 
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/:formDefinitionCode/*`}
                element={
                    <MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute
                        resolveBeforeLoad
                        formMapping={formMapping}
                    >
                        <QuerySummary />
                    </MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute>
                }
            />
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/queries/*`}
                element={
                    <MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute
                        resolveBeforeLoad
                    >
                        <QuerySummary />
                    </MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute>
                }
            />
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/*`}
                element={
                    <MedicalReviewByPatientStudyNumberAndRepeatRoute
                        resolveBeforeLoad
                    >
                        <MedicalReview />
                    </MedicalReviewByPatientStudyNumberAndRepeatRoute>
                }
            />
            <Route 
                path={`/medical-review/:patientStudyNumber/*`}
                element={
                    <MedicalReviewsByPatientStudyNumberRoute
                        resolveBeforeLoad
                    >
                        <Patient />
                    </MedicalReviewsByPatientStudyNumberRoute>
                }
            />
            <Route
                path="/query-report/queries"
                element={
                    <IFramePage url="/queries" />
                }
            />
            <Route 
                path="/medical-review"
                element={<Patients />}
            />
            <Route path="*" element={<Navigate replace to="/medical-review" />} />
        </Routes>
    )
}

const App: FunctionComponent = () => {
    const formsContext = useContext(FormsContext);
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const medicalReviewExtensionContext = useContext(MedicalReviewExtensionContext);

    return (
        <FormsLayout>
            <AppRoutes />
        </FormsLayout>
    );
};

export default App;
