/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import { FieldContext, IInputRenderProps, useScopedField } from '@ngt/forms-core';
import { Button, Theme, Tooltip } from '@mui/material';
import { getParentPropertyPath, IValidationError } from '@ngt/forms';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-duotone-svg-icons';
import { InstitutionContext } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type SymbolFieldProps = IInputRenderProps<string, IValidationError>

export interface IOpmsSaeDisplayProps extends SymbolFieldProps {
    opmsUrl: string;
    saeIdName: string;
    saeIdPrependParentName?: boolean;
    saeIdTableCell?: boolean;
    textValueButton?: boolean;
    btnMinWidth?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        minWidth: '0',
        padding: theme.spacing(1)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const subscription = { value: true };

const OpmsSaeDisplay: React.FunctionComponent<IOpmsSaeDisplayProps> = ({
    inputRender: { state: { value, ...restInputState }, actions: { ...restInputActions } },
    opmsUrl,
    saeIdName,
    saeIdPrependParentName,
    saeIdTableCell,
    textValueButton,
    btnMinWidth,
    ...rest
}) => {
    const { classes } = useStyles();

    const parentContext = React.useContext(FieldContext);

    const computedSaeIdName = React.useMemo(() => {
        if (parentContext?.name && saeIdPrependParentName === true) {
            const parentName = saeIdTableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (saeIdName.startsWith('[')) {
                return `${parentName}${saeIdName}`;
            }

            return `${parentName}.${saeIdName}`;
        }

        return saeIdName;
    }, [parentContext?.name, saeIdName, saeIdPrependParentName, saeIdTableCell]);

    const { state: { value: saeId } } = useScopedField<number, IValidationError>(computedSaeIdName, subscription, false);

    const { data: institution } = useContext(InstitutionContext);

    if (!saeId || !institution) {
        return null;
    }

    if (textValueButton) {
        return (
            <Tooltip title="View Form">
                <Button color="primary" variant="outlined" style={{ minWidth: btnMinWidth ?? 'auto' }} component="a" target="_blank" href={`${opmsUrl}sae/institution/${institution.code}/view-sae/${saeId}`}>
                    {value}
                </Button>
            </Tooltip>
        )
    }

    return (
        <Tooltip title="View Form">
            <Button color="primary" variant="contained" className={classes.button} component="a" target="_blank" href={`${opmsUrl}sae/institution/${institution.code}/view-sae/${saeId}`}>
                <FontAwesomeIcon icon={faExternalLink} />
            </Button>
        </Tooltip>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default OpmsSaeDisplay;
