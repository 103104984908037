import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { GroupedFields, Input, TextDisplay, InputOnlyField, IValidationError } from '@ngt/forms';
import { MedicalReviewStatus, SourceDocument } from '../../apis/dtos';
import { Accordion, AccordionDetails, AccordionSummary, Box, Theme, Typography, useTheme } from '@mui/material';
import { Condition, CountDisplay, ICondition, GroupedSubformGrid, IGroupedSubformGridCell, OpenClinicaFileDisplay } from '@ngt/forms-medical-review';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { DateTime } from 'luxon';

interface IFormDates {
}


const useStyles = makeStyles()((theme: Theme) => ({
    datesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center'
    },
    dateContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const conditionSubscription = { value: true };

const FormDates: FunctionComponent<IFormDates> = () => {
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            datesContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center'
            },
            dateContainer: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
            }
        }
    }, [theme]);

    const { state: { value: formStatus } } = useScopedField<MedicalReviewStatus, IValidationError>('medicalReviewStatus', conditionSubscription, false);
    const { state: { value: updateDate } } = useScopedField<string, IValidationError>('updateDate', conditionSubscription, false);
    const { state: { value: completeDate } } = useScopedField<string, IValidationError>('completeDate', conditionSubscription, false);
    const { state: { value: cancelDate } } = useScopedField<string, IValidationError>('cancelDate', conditionSubscription, false);

    return (
        <Box sx={styles.datesContainer}>
            {
                updateDate && <Box sx={styles.dateContainer}>
                    <Typography variant="caption" sx={{ marginRight: theme.spacing(1) }}>
                        Date Updated:
                    </Typography>
                    <Typography variant="caption">
                        {DateTime.fromISO(updateDate!).toFormat('dd/MM/yyyy, hh:mm a')}
                    </Typography>
                </Box>
            }
            {
                formStatus === MedicalReviewStatus.Complete &&
                completeDate && <Box sx={styles.dateContainer}>
                    <Typography variant="caption" sx={{ marginRight: theme.spacing(1) }}>
                        Date Completed:
                    </Typography>
                    <Typography variant="caption">
                        {DateTime.fromISO(completeDate!).toFormat('dd/MM/yyyy, hh:mm a')}
                    </Typography>
                </Box>
            }
            {
                formStatus === MedicalReviewStatus.Cancelled &&
                cancelDate && <Box sx={styles.dateContainer}>
                    <Typography variant="caption" sx={{ marginRight: theme.spacing(1) }}>
                        Date Cancelled:
                    </Typography>
                    <Typography variant="caption">
                        {DateTime.fromISO(cancelDate!).toFormat('dd/MM/yyyy, hh:mm a')}
                    </Typography>
                </Box>
            }
        </Box>
    );
};

export default FormDates;
