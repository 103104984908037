import { Box, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, GroupedField, OpenClinicaFormDisplay } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay } from '@ngt/forms';
import { GeneralInformation as GeneralInformationDto, ProtocolDeviation, Comment, StratificationFactor, LostToFollowUp } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import FormDates from '../../components/form/FormDates';
import Annotations from '../../components/form/Annotations';


declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface IGeneralInformationProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 2)
    },
    subtitle2: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

//const hasNoWaiver: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.waivered;
//const hasWaiver: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.waivered;

const hasNoDeviations: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.protocolDeviations || value?.protocolDeviations?.length === 0;
const hasDeviations: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.protocolDeviations && value?.protocolDeviations?.length > 0;

const patientIneligibleNotApplicable: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => value?.participantIneligible !== true;
const patientIneligibleApplicable: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => value?.participantIneligible === true;

const excludeForAnalysisNotApplicable: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => value?.excludeFromAnalysis !== true;
const excludeForAnalysisApplicable: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => value?.excludeFromAnalysis === true;

const hasNoStratFactors: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.stratificationFactors || value?.stratificationFactors?.length === 0;
const hasStratFactors: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.stratificationFactors && value?.stratificationFactors?.length > 0;

const hasNotWithdrawn: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => value?.withdrawn !== true;
const hasWithdrawn: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => value?.withdrawn === true;

const notLostToFollowUp: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.lostToFollowUps || value?.lostToFollowUps?.length === 0;
const lostToFollowUp: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.lostToFollowUps && value?.lostToFollowUps?.length > 0;

const hasNoComments: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.comments || value?.comments?.length === 0;
const hasComments: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.comments && value?.comments?.length > 0;

const conditionSubscription = { value: true };

const protocoldeviationColumns: IGroupedSubformGridCell<ProtocolDeviation>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'crfName',
        content: (
            <Input component={TextDisplay} />
        ),
        width: 250,
        sortValue: (row) => row?.crfName
    },
    {
        name: 'description',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        width: 550,
        sortValue: (row) => row?.description
    },
    {
        name: 'detailedNote',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.detailedNote
    }
];

const stratificationFactorColumns: IGroupedSubformGridCell<StratificationFactor>[] = [
    {
        name: 'stratFactor',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.stratFactor
    },
    {
        name: 'randomisedAs',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.randomisedAs
    },
    {
        name: 'correctStrat',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.correctStrat
    },
    {
        name: 'comments',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.comments
    }
];

const lostToFollowUpColumns: IGroupedSubformGridCell<LostToFollowUp>[] = [
    {
        name: 'datePatientStatusAssessed',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: (row) => row?.datePatientStatusAssessed
    },
    {
        name: 'visit',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    }
];

const commentColumns: IGroupedSubformGridCell<Comment>[] = [
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: (row) => row?.date
    },
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'crfName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.crfName
    },
    {
        name: 'commentText',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.commentText
    }
];

const GeneralInformation: FunctionComponent<IGeneralInformationProps> = () => {
    const {classes} = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Protocol Deviations
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasDeviations}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ProtocolDeviation}
                            name="protocolDeviations"
                            columns={protocoldeviationColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNoDeviations}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Protocol Deviations"
                                description="There is no protocol deviation associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Ineligible/Analysis Exclusion
                    </Typography>

                    <Typography variant="h5" className={classes.subtitle2}>
                        Ineligible Patient
                    </Typography>

                    <Condition
                        type={GeneralInformationDto}
                        condition={patientIneligibleApplicable}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="dateOfIneligibilityConfirmed"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="reviewer"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="reasonsForIneligibility"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="ineligibilityComments"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={patientIneligibleNotApplicable}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="Not Applicable"
                                description="Patient is not ineligible."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>

                    <Typography variant="h5" className={classes.subtitle2}>
                        Exclude From Analysis
                    </Typography>

                    <Condition
                        type={GeneralInformationDto}
                        condition={excludeForAnalysisApplicable}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="dateExclusionConfirmed"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="statistician"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="reasonsForExclusion"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="exclusionComments"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>

                    <Condition
                        type={GeneralInformationDto}
                        condition={excludeForAnalysisNotApplicable}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="Not Applicable"
                                description="Patient is not excluded from analysis."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Stratification Factors
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasStratFactors}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={StratificationFactor}
                            name="stratificationFactors"
                            columns={stratificationFactorColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNoStratFactors}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Stratification Factors"
                                description="There are no stratification factors associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Withdrawal of Consent
                    </Typography>

                    

                    <Condition
                        type={GeneralInformationDto}
                        condition={hasWithdrawn}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="withdrawalOfConsentDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="withdrawalOfConsentReason"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <Typography variant="body1" className={classes.subtitle}>
                            <strong>NOTE to Medical Reviewers</strong>: For EXPERT study the "Withdrawal of Consent form" records full consent withdrawal only. Change of level of consent regarding data collection is noted in the Visit General Form:
                            "How is the patient's status confirmed?" = In person at clinic; Telephone or other forms of contact with patient; Communication from other medical practitioners of the patient; Cancer registry; other - specify.
                        </Typography>
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNotWithdrawn}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="Not Applicable"
                                description="Patient has not withdrawn consent."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Lost to Follow-up
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={lostToFollowUp}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={LostToFollowUp}
                            name="lostToFollowUps"
                            columns={lostToFollowUpColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={notLostToFollowUp}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="Not Applicable"
                                description="Patient is not lost to follow-up."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Comments and Issues
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasComments}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={Comment}
                            name="comments"
                            columns={commentColumns}
                            disabled
                        />

                        <Typography variant="body1" className={classes.subtitle}>
                            <strong>NOTE to Medical Reviewers</strong>: For EXPERT study, no waiver functionality is available in the database. Any waivers were noted in the Comments and Issues section, and should be noted in the applicable database field as a protocol deviation.
                        </Typography>
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNoComments}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Comments and Issues"
                                description="There are no comments and issues associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />

            </ContextMedicalReviewForm>
        </>
    );
};

export default GeneralInformation;
