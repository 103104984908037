import { Box, Theme, Tooltip, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, OpenClinicaFormDisplay, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay } from '@ngt/forms';
import { BreastImagingForm, BreastImaging as BreastImagingDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import FormDates from '../../components/form/FormDates';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface IBreastImagingProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const breastImagingColumns: IGroupedSubformGridCell<BreastImagingDto>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'imagingType',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.imagingType
    },
    {
        name: 'imagingDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.imagingDate
    },
    {
        name: 'laterality',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.laterality,
        header: <>
            <Tooltip title={<>Annual bilateral imaging is required</>}>
                <Typography>Laterality</Typography>
            </Tooltip>
        </>
    },
    {
        name: 'result',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.result,
    }
];


const hasNoBreastImaging: ICondition<BreastImagingForm> = ({ formState: { value } = {} }) => !value?.breastImagings || value?.breastImagings?.length === 0;
const hasBreastImaging: ICondition<BreastImagingForm> = ({ formState: { value } = {} }) => !!value?.breastImagings && value?.breastImagings?.length > 0;

const conditionSubscription = { value: true };


const BreastImaging: FunctionComponent<IBreastImagingProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>

                <GroupedField
                    name="bilateralBreastImagingDate"
                    component={DateDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="primaryTumourLaterality"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Breast Imaging
                    </Typography>

                    <Condition
                        type={BreastImagingForm}
                        condition={hasBreastImaging}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.gridSize}>
                            <GroupedSubformGrid
                                type={BreastImagingDto}
                                name="breastImagings"
                                columns={breastImagingColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={BreastImagingForm}
                        condition={hasNoBreastImaging}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Breast Imaging"
                                description="There is no breast imaging associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default BreastImaging;
