import { Box, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, OpenClinicaFormDisplay, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay } from '@ngt/forms';
import { CancerEvents, LocalRecurrence, RegionalRecurrence, DistantRecurrence, ContralateralBreastCancer, SecondPrimaryInvasiveCancer } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import FormDates from '../../components/form/FormDates';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface ICancerEventsFormProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const localRecurrenceColumns: IGroupedSubformGridCell<LocalRecurrence>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'site',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'invasive',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="invasiveSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'invasiveSpecify'
                }
            ]
        },
        sortValue: (row) => !!row?.invasive ? `${row?.invasive} - ${row?.invasiveSpecify}` : undefined
    },
    {
        name: 'erStatus',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.erStatus
    },
    {
        name: 'prStatus',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.prStatus
    },
    {
        name: 'her2Status',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.her2Status
    },
    {
        name: 'suspiciousDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.suspiciousDate
    },
    {
        name: 'definiteDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.definiteDate
    },
    {
        name: 'tumourBlockAvailable',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.tumourBlockAvailable
    }
];

const regionalRecurrenceColumns: IGroupedSubformGridCell<RegionalRecurrence>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'site',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'erStatus',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.erStatus
    },
    {
        name: 'prStatus',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.prStatus
    },
    {
        name: 'her2Status',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.her2Status
    },
    {
        name: 'suspiciousDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.suspiciousDate
    },
    {
        name: 'definiteDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.definiteDate
    }
];

const distantRecurrenceColumns: IGroupedSubformGridCell<DistantRecurrence>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'site',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'suspiciousForRecurrence',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.suspiciousForRecurrence
    },
    {
        name: 'definiteRecurrence',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.definiteRecurrence
    }
];

const contralateralBreastCancerColumns: IGroupedSubformGridCell<ContralateralBreastCancer>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'suspiciousInvestigation',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.suspiciousInvestigation
    },
    {
        name: 'suspiciousInvestigationDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.suspiciousInvestigationDate
    },
    {
        name: 'definiteDiagnosis',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="definiteDiagnosisSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'definiteDiagnosisSpecify'
                }
            ]
        },
        sortValue: (row) => !!row?.definiteDiagnosis ? `${row?.definiteDiagnosis} - ${row?.definiteDiagnosisSpecify}` : undefined
    },
    {
        name: 'definiteDiagnosisDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.definiteDiagnosisDate
    },
];

const secondPrimaryInvasiveColumns: IGroupedSubformGridCell<SecondPrimaryInvasiveCancer>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'diagnosisDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.diagnosisDate
    },
    {
        name: 'site',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'siteDescription',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.siteDescription
    }
];


const hasNoLocalRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !value?.localRecurrences || value?.localRecurrences?.length === 0;
const hasLocalRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !!value?.localRecurrences && value?.localRecurrences?.length > 0;

const hasNoRegionalRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !value?.regionalRecurrences || value?.regionalRecurrences?.length === 0;
const hasRegionalRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !!value?.regionalRecurrences && value?.regionalRecurrences?.length > 0;

const hasNoDistantRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !value?.distantRecurrences || value?.distantRecurrences?.length === 0;
const hasDistantRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !!value?.distantRecurrences && value?.distantRecurrences?.length > 0;

const hasNoContralateralBreastCancer: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !value?.contralateralBreastCancers || value?.contralateralBreastCancers?.length === 0;
const hasContralateralBreastCancer: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !!value?.contralateralBreastCancers && value?.contralateralBreastCancers?.length > 0;

const hasNoSecondPrimaryInvasive: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !value?.secondPrimaryInvasiveCancers || value?.secondPrimaryInvasiveCancers?.length === 0;
const hasSecondPrimaryInvasive: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !!value?.secondPrimaryInvasiveCancers && value?.secondPrimaryInvasiveCancers?.length > 0;

const conditionSubscription = { value: true };


const CancerEventsForm: FunctionComponent<ICancerEventsFormProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>

                <GroupedField
                    name="bilateralBreastImagingDate"
                    component={DateDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="primaryTumourLaterality"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="primaryLocationOfTumour"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Local Recurrence
                    </Typography>

                    <Condition
                        type={CancerEvents}
                        condition={hasLocalRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.gridSize}>
                            <GroupedSubformGrid
                                type={LocalRecurrence}
                                name="localRecurrences"
                                columns={localRecurrenceColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={CancerEvents}
                        condition={hasNoLocalRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Local Recurrence"
                                description="There is no local recurrence associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Regional Recurrence
                    </Typography>

                    <Condition
                        type={CancerEvents}
                        condition={hasRegionalRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={RegionalRecurrence}
                            name="regionalRecurrences"
                            columns={regionalRecurrenceColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={CancerEvents}
                        condition={hasNoRegionalRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Regional Recurrence"
                                description="There is no regional recurrence associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Distant Recurrence
                    </Typography>

                    <Condition
                        type={CancerEvents}
                        condition={hasDistantRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={DistantRecurrence}
                            name="distantRecurrences"
                            columns={distantRecurrenceColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={CancerEvents}
                        condition={hasNoDistantRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Distant Recurrence"
                                description="There is no distant recurrence associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Contralateral Breast Cancer
                    </Typography>

                    <Condition
                        type={CancerEvents}
                        condition={hasContralateralBreastCancer}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ContralateralBreastCancer}
                            name="contralateralBreastCancers"
                            columns={contralateralBreastCancerColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={CancerEvents}
                        condition={hasNoContralateralBreastCancer}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Contralateral Breast Cancer"
                                description="There is contralateral breast cancer associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Second (non-breast) primary invasive cancer
                    </Typography>

                    <Condition
                        type={CancerEvents}
                        condition={hasSecondPrimaryInvasive}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={SecondPrimaryInvasiveCancer}
                            name="secondPrimaryInvasiveCancers"
                            columns={secondPrimaryInvasiveColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={CancerEvents}
                        condition={hasNoSecondPrimaryInvasive}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Second (non-breast) Primary Invasive Cancer"
                                description="There is no second (non-breast) primary invasive cancer associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default CancerEventsForm;
