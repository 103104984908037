import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, NumericDisplay, FieldProvider, FormLabel } from '@ngt/forms';
import {
    BaselineMedicalHistory as BaselineMedicalHistoryDto,
    CardiovascularHistory
} from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import FormDates from '../../components/form/FormDates';
import Annotations from '../../components/form/Annotations';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface IBaselineMedicalHistoryProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 2)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    noBorderRow: {
        border: `${0} !important`,

        '& > *': {
            border: `${0} !important`
        }
    },
    greyRow: {
        '& td': {
            backgroundColor: theme.palette.grey[200]
        }
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const conditionSubscription = { value: true };

const hasNoCardivascularHistory: ICondition<BaselineMedicalHistoryDto> = ({ formState: { value } = {} }) => !value?.cardiovascularHistories || value?.cardiovascularHistories?.length === 0;
const hasCardiovascularHistory: ICondition<BaselineMedicalHistoryDto> = ({ formState: { value } = {} }) => !!value?.cardiovascularHistories && value?.cardiovascularHistories?.length > 0;

const cardiovascularHistoryColumns: IGroupedSubformGridCell<CardiovascularHistory>[] = [
    //{
    //    name: 'event',
    //    content: (
    //        <Input
    //            component={OpenClinicaFormDisplay}
    //            openClinicaUrl={openClinicaUrl}
    //            eventCrfIdName="eventCrfId"
    //            studyEventIdName="studyEventId"
    //            btnMinWidth="140px"
    //            eventCrfIdPrependParentName
    //            eventCrfIdTableCell
    //            studyEventIdPrependParentName
    //            studyEventIdTableCell
    //            textValueButton
    //        />
    //    ),
    //    sortValue: (row) => row?.event
    //},
    {
        name: 'historyType',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.historyType
    },
    {
        name: 'historyDate',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.historyDate
    }
];


const BaselineMedicalHistory: FunctionComponent<IBaselineMedicalHistoryProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>

                <Typography variant="h2" className={classes.subtitle}>
                    Cardiovascular History
                </Typography>

                <Table>
                    <TableHead>
                    </TableHead>
                    <TableBody>
                        <TableRow className={`${classes.greyRow} ${classes.noBorderRow}`}>
                            <TableCell>
                                <FieldProvider name="myocardialInfarction">
                                    <FormLabel />
                                </FieldProvider>
                            </TableCell>
                            <TableCell>
                                <InputOnlyField
                                    name="myocardialInfarction"
                                    component={TextDisplay}
                                />
                            </TableCell>
                            <TableCell>
                                <FieldProvider name="myocardialInfarctionDiagnosis">
                                    <FormLabel />
                                </FieldProvider>
                                <InputOnlyField
                                    name="myocardialInfarctionDiagnosis"
                                    component={TextDisplay}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow className={`${classes.noBorderRow}`}>
                            <TableCell>
                                <FieldProvider name="strokeIschaemicAttack">
                                    <FormLabel />
                                </FieldProvider>
                            </TableCell>
                            <TableCell>
                                <InputOnlyField
                                    name="strokeIschaemicAttack"
                                    component={TextDisplay}
                                />
                            </TableCell>
                            <TableCell>
                                <FieldProvider name="strokeIschaemicAttackDiagnosis">
                                    <FormLabel />
                                </FieldProvider>
                                <InputOnlyField
                                    name="strokeIschaemicAttackDiagnosis"
                                    component={TextDisplay}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow className={`${classes.greyRow} ${classes.noBorderRow}`}>
                            <TableCell>
                                <FieldProvider name="angina">
                                    <FormLabel />
                                </FieldProvider>
                            </TableCell>
                            <TableCell>
                                <InputOnlyField
                                    name="angina"
                                    component={TextDisplay}
                                />
                            </TableCell>
                            <TableCell>
                                <FieldProvider name="anginaDiagnosis">
                                    <FormLabel />
                                </FieldProvider>
                                <InputOnlyField
                                    name="anginaDiagnosis"
                                    component={TextDisplay}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow className={`${classes.noBorderRow}`}>
                            <TableCell>
                                <FieldProvider name="thromboembolicEvent">
                                    <FormLabel />
                                </FieldProvider>
                            </TableCell>
                            <TableCell>
                                <InputOnlyField
                                    name="thromboembolicEvent"
                                    component={TextDisplay}
                                />
                            </TableCell>
                            <TableCell>
                                <FieldProvider name="thromboembolicEventDiagnosis">
                                    <FormLabel />
                                </FieldProvider>
                                <InputOnlyField
                                    name="thromboembolicEventDiagnosis"
                                    component={TextDisplay}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow className={`${classes.greyRow} ${classes.noBorderRow}`}>
                            <TableCell>
                                <FieldProvider name="hypertension">
                                    <FormLabel />
                                </FieldProvider>
                            </TableCell>
                            <TableCell>
                                <InputOnlyField
                                    name="hypertension"
                                    component={TextDisplay}
                                />
                            </TableCell>
                            <TableCell>
                                <FieldProvider name="hypertensionDiagnosis">
                                    <FormLabel />
                                </FieldProvider>
                                <InputOnlyField
                                    name="hypertensionDiagnosis"
                                    component={TextDisplay}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow className={`${classes.noBorderRow}`}>
                            <TableCell>
                                <FieldProvider name="hypercholesterolemia">
                                    <FormLabel />
                                </FieldProvider>
                            </TableCell>
                            <TableCell>
                                <InputOnlyField
                                    name="hypercholesterolemia"
                                    component={TextDisplay}
                                />
                            </TableCell>
                            <TableCell>
                                <FieldProvider name="hypercholesterolemiaDiagnosis">
                                    <FormLabel />
                                </FieldProvider>
                                <InputOnlyField
                                    name="hypercholesterolemiaDiagnosis"
                                    component={TextDisplay}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Additional Cardiovascular History
                    </Typography>

                    <Condition
                        type={BaselineMedicalHistoryDto}
                        condition={hasCardiovascularHistory}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.gridSize}>
                            <GroupedSubformGrid
                                type={CardiovascularHistory}
                                name="cardiovascularHistories"
                                columns={cardiovascularHistoryColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={BaselineMedicalHistoryDto}
                        condition={hasNoCardivascularHistory}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Cardiovascular History"
                                description="There is no additional cardiovascular history associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <Box style={{ marginTop: '4px' }}>
                    <GroupedField name="connectiveTissueDisorder"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </Box>
                <GroupedField name="diabetes"
                    component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="rheumatoidArthritis"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <Typography variant="h2" className={classes.subtitle}>
                    Tobacco History
                </Typography>

                <GroupedField name="hasThePatientEvenBeenASmoker"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="isThePatientACurrentSmoker"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="averageNumberOfCigarettesPerDay"
                    component={NumericDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField name="totalNumberOfYearsAsASmoker"
                    component={NumericDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default BaselineMedicalHistory;
