import { Box, Grid, Theme, Typography, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, OpenClinicaFormDisplay } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, IValidationError } from '@ngt/forms';
import { TreatmentToxicityForm as TreatmentToxicityFormDto, SeriousAdverseEvent, FollowUp, TreatmentToxicity } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode, useScopedField } from '@ngt/forms-core';
import { BasicAlert, useFullWidthLayout } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import { DateTime } from 'luxon';
import FormDates from '../../components/form/FormDates';
import OpmsSaeDisplay from './input/display/OpmsSaeDisplay';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;
const opmsUrl = global.OPMS_URL as string;

interface ITreatmentToxicityFormProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    subtitle2: {
        padding: theme.spacing(0, 2, 2)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const treatmentToxicityColumns: IGroupedSubformGridCell<TreatmentToxicity>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'aeClass',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.aeClass
    },
    {
        name: 'aeTerm',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.aeTerm
    },
    {
        name: 'aeGrade',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.aeGrade
    },
    {
        name: 'breastRadiationTherapyRelated',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.breastRadiationTherapyRelated
    },
    {
        name: 'endocrineTherapyRelated',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.endocrineTherapyRelated
    }
];

const seriousAdverseEventColumns: IGroupedSubformGridCell<SeriousAdverseEvent>[] = [
    {
        name: 'saeNumber',
        content: (
            <Input
                component={OpmsSaeDisplay}
                opmsUrl={`${opmsUrl}`}
                saeIdName="opmsSaeId"
                btnMinWidth="50px"
                saeIdPrependParentName
                saeIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.saeNumber
    },
    {
        name: 'ctcaeClass',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.ctcaeClass
    },
    {
        name: 'ctcaeTerm',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.ctcaeTerm
    },
    {
        name: 'ctcaeGrade',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.ctcaeGrade
    },
    {
        name: 'onset',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.onset
    },
    {
        name: 'outcome',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.outcome
    },
    {
        name: 'outcomeDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.outcomeDate
    },
    {
        name: 'endocrineTherapy',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        minWidth: 250,
        sortValue: (row) => row?.endocrineTherapy
    },
    {
        name: 'radiationTherapy',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        minWidth: 250,
        sortValue: (row) => row?.radiationTherapy
    },
    {
        name: 'medicalReviewerComments',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.medicalReviewerComments
    },
    {
        name: 'siteComments',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.siteComments
    }
];

const hasNoVisitDates: ICondition<TreatmentToxicityFormDto> = ({ formState: { value } = {} }) => !value?.followUps || value?.followUps?.length === 0;
const hasVisitDates: ICondition<TreatmentToxicityFormDto> = ({ formState: { value } = {} }) => !!value?.followUps && value?.followUps?.length > 0;

const hasNoTreatmentToxicity: ICondition<TreatmentToxicityFormDto> = ({ formState: { value } = {} }) => !value?.treatmentToxicities || value?.treatmentToxicities?.length === 0;
const hasTreatmentToxicity: ICondition<TreatmentToxicityFormDto> = ({ formState: { value } = {} }) => !!value?.treatmentToxicities && value?.treatmentToxicities?.length > 0;

const hasNoSaes: ICondition<TreatmentToxicityFormDto> = ({ formState: { value } = {} }) => !value?.seriousAdverseEvents || value?.seriousAdverseEvents?.length === 0;
const hasSaes: ICondition<TreatmentToxicityFormDto> = ({ formState: { value } = {} }) => !!value?.seriousAdverseEvents && value?.seriousAdverseEvents?.length > 0;

const conditionSubscription = { value: true };

const VisitDates = () => {
    const { state: { value: followUps } } = useScopedField<Array<FollowUp>, IValidationError>('followUps', conditionSubscription, false);

    const theme = useTheme();

    if (!followUps) {
        return <></>;
    }

    return (
        <Grid
            container
            spacing={1}
            sx={{
                width: '100% !important',
                padding: theme.spacing(2),

            }}
        >
            {
                followUps?.map((followUp, i) => {
                    return (
                        <Grid
                            item
                            sx={{
                                minWidth: '220px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    border: '1px solid black',
                                    padding: theme.spacing(4, 2),
                                    width: '100%'
                                }}
                            >
                                <Typography style={{ marginRight: theme.spacing(1), fontWeight: 500 }}>
                                    {followUp.eventName}
                                </Typography>
                                <Typography>
                                    {
                                        followUp.assessmentDate ? DateTime.fromISO(followUp.assessmentDate).toFormat('dd/MM/yyyy') : ''
                                    }
                                </Typography>
                            </div>
                        </Grid>
                    );
                })
            }
        </Grid>
    );

}

const TreatmentToxicityForm: FunctionComponent<ITreatmentToxicityFormProps> = () => {
    const { classes } = useStyles();

    useFullWidthLayout();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        Treatment Toxicity and SAEs
                    </Typography>
                        
                    <FormDates />
                </Box>
                <Typography className={classes.subtitle2}>
                    If the patient experiences an adverse event with differing levels of severity during a follow-up period, the highest grade experienced should be reported and the relationship of the highest grade to either radiation therapy or endocrine therapy specified.
                    <br />
                    Note: Basal or squamous cell carcinoma of the skin, cervical carcinoma in situ or bladder cancer in situ does not need to be recorded on Treatment Toxicities Form. An SAE form is not required to be submitted for these diagnoses, either.
                    <br /><br />
                    <strong>If the participant had a SAE reported corresponding adverse event is reported on this form. The same NCI CTCAE term, grade as well as relatedness should be reported on both SAE and Treatment Toxicities forms.</strong>
                </Typography>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Visit Dates
                    </Typography>

                    <Typography className={classes.subtitle}>
                        (Date participant status assessed on the OC General Form)
                    </Typography>

                    <Condition
                        type={TreatmentToxicityFormDto}
                        condition={hasVisitDates}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <VisitDates />
                    </Condition>
                    <Condition
                        type={TreatmentToxicityFormDto}
                        condition={hasNoVisitDates}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Visit Dates"
                                description="There is no visit date associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Treatment Toxicities
                    </Typography>

                    <Condition
                        type={TreatmentToxicityFormDto}
                        condition={hasTreatmentToxicity}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.gridSize}>
                            <GroupedSubformGrid
                                type={TreatmentToxicity}
                                name="treatmentToxicities"
                                columns={treatmentToxicityColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={TreatmentToxicityFormDto}
                        condition={hasNoTreatmentToxicity}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Treatment Toxicities"
                                description="There is no treatment toxicity associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Serious Adverse Event Summary
                    </Typography>

                    <Typography className={classes.subtitle}>
                        Please click on the SAE number to review the full SAE details in the SAE review module (including treatments and procedures).
                    </Typography>

                    <Condition
                        type={TreatmentToxicityFormDto}
                        condition={hasSaes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div style={{ fontSize: '0.750rem !important' }}>
                            <GroupedSubformGrid
                                type={SeriousAdverseEvent}
                                name="seriousAdverseEvents"
                                columns={seriousAdverseEventColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={TreatmentToxicityFormDto}
                        condition={hasNoSaes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Serious Adverse Events"
                                description="There is no serious adverse event associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default TreatmentToxicityForm;
